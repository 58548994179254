<template>
  <section class="tabsResultsNew">
    <!--  -->
    <SondiChart
      v-if="resultedData"
      ident="chartOne"
      ref="chartOne"
      :title="$t('test-szondi.front')"
      :result-label-string="$t('test-szondi.resultFront')"
      :data-plus="resultedData.frontPlus"
      :data-minus="resultedData.frontMinus"
      :vectors-values="resultedData.frontResult"
    />
    <SondiChart
      v-if="resultedData"
      ident="chartTwo"
      ref="chartTwo"
      :title="$t('test-szondi.back')"
      :result-label-string="$t('test-szondi.resultBack')"
      :data-plus="resultedData.backPlus"
      :data-minus="resultedData.backMinus"
      :vectors-values="resultedData.backResult"
    />
  </section>
</template>

<script>
import SondiChart from "./SondiChart.vue";
export default {
  components: {
    SondiChart,
  },
  props: {
    resultedData: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.resultCaption,
.resultNormal {
  text-align: left;
  max-width: 350px;
}
.resultCaption {
  font-weight: bold;
}
.tabsResultsNew {
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 700px; */
  // margin-top: 30px;

  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: center;

  margin-bottom: 30px;
  text-align: center;
  max-width: 800px;
}

.tabsResultsNew {
  /* width: 100vw; */
  max-width: 1000px;
  /* width: fit-content; */
}

.sondi-results
  > div.tabsResults.ant-tabs.ant-tabs-top.ant-tabs-line
  > div.ant-tabs-bar.ant-tabs-top-bar {
  text-align: center;
}

.resultsTitle {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: large;
}
</style>
